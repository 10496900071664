import React from "react";
import HeaderDesktop from "./headerDesktop";
import HeaderMobile from "./headerMobile";
import { useCheckScreenSize } from "@util/hooks"

const Header = () => {

  const { isLargeDown } = useCheckScreenSize();

  return (
    <header>
      {isLargeDown ? <HeaderMobile /> : <HeaderDesktop />}
    </header>
  );
};

export default Header;
