import styled from "styled-components";
import { fontWeights, colors, transitionAll, PRIMARY_COLOR } from "@util/constants";

const navSidePadding = 63;
export const HeaderMobileWrapper = styled.div<{
  mobHeaderHeight: number;
  activeMenu: boolean;
}>`
  position:fixed;
  width:100%;
  top:0;
  left:0;
  z-index:20;
  color:${PRIMARY_COLOR};

  ${({ mobHeaderHeight }) => mobHeaderHeight && `
    .this-child{
      top:${mobHeaderHeight}px;
    }
  `}

  ${({ activeMenu }) => activeMenu && `
    background-color:#fff;
  `}
`

export const MobHeader = styled.div`
  padding:20px;
  display:flex;
  justify-content: space-between;
  align-items:center;
`

export const MobNav = styled.nav`
  background:#fff;
  padding:${navSidePadding}px;
`

export const ParentUl = styled.ul<{}>`
  list-style-type:none;
`

export const ParentLi = styled.li<{
  dropDownActive: boolean;
}>`
  &:not(:last-child),
  li:not(:last-child){
    margin-bottom:41px;
  }
  font-weight:${fontWeights.bold};
  font-size:23px;
  a{
    opacity: 0.4;
  }
  .parentLi-text{
    color:${PRIMARY_COLOR};
    display:flex;
    align-items:center;
    cursor:pointer;
    svg{
      margin-left:60px;
      transform: rotate(-90deg);
    }
  }
  svg{
    font-size:1em;
    margin-top:1.5px;
    path{
      stroke:${PRIMARY_COLOR};
    }
  }
  .this-child{
    background-color:#fff;
    position:fixed;
    width:100%;
    height:100%;
    right:-100%;
    z-index:30;
    padding:63px ${navSidePadding}px 63px;
    ${transitionAll}
    h3{
      display:flex;
      align-items:center;
      margin-bottom:41px;
      font-size:23px;
      cursor:pointer ;
      span{
        font-weight:${fontWeights.bold};
        margin-left:30px;
      }
      svg{
        transform: rotate(90deg);
      }
    }
    ul{
      list-style-type:none;
    }
  }
  .this-child-active{
    right:0;
  }
`