import React, { ReactNode, MouseEvent } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled, { CSSProperties } from "styled-components";

import { A } from "@util/standard";
import { Color } from "@util/types";
import { Maybe, Scalars, SanityFileAssetConnection } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT, fontWeights, transitionAll } from "@util/constants";
import { IconDownCircle } from "@util/assets";

interface Props {
  children?: ReactNode;
  linkText?: Maybe<Scalars["String"]>;
  externalLink?: Maybe<Scalars["String"] | SanityFileAssetConnection>;
  internalLink?: Maybe<any>;
  className?: string;
  color?: Color;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  margin?: string;
  width?: string;
  mobileWidth?: string;
  fontSize?: string;
  fontFamily?: string;
  animatedSvg?: boolean;
  display?: CSSProperties["display"];
  preventDefault?: boolean;
  design?: LinkDesign;
}

interface LinkChildProps {
  children?: ReactNode;
  linkText?: Maybe<Scalars["String"]>;
}

type LinkDesign = "primary" | undefined;

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  opacity?: number;
  margin: string | undefined;
  color?: Color;
  hoverColor?: Color;
  width?: string;
  mobileWidth?: string;
  fontSize?: string;
  fontFamily?: string;
  animatedSvg?: boolean;
  display?: CSSProperties["display"];
  design?: LinkDesign;
}>`
  user-select: none;
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ color }) => color && `color: ${colors[color]}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  ${({ width }) => width && `width: ${width}`};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  }

  ${({ hoverColor }) =>
    hoverColor &&
    `
    &:hover{
      color: ${colors[hoverColor]};
    }
  `};

  ${({ animatedSvg }) =>
    animatedSvg &&
    `
    svg{
      transition: ease transform 0.3s;
    }
    &:hover{
      svg{
        transform: translateX(3px);
      }
    }
  `}

`;

const StyledLinkInner = styled.span<{
  design?: LinkDesign
}>`
  ${transitionAll}
  ${({ design }) => design === 'primary' && `
    display:flex;
    align-items:center;
    svg{
      transform:rotate(-90deg);
      margin-left:15px;
      circle,path{
        stroke: ${colors.textBody}
      }
    }
    &:hover{
      opacity:.5;
    }
  `}
`

const LinkChild = ({ children, linkText }: LinkChildProps) => {
  if (children) {
    return <>{children}</>;
  }

  return <>{linkText ?? "Button"}</>;
};

const Link = ({
  linkText,
  internalLink,
  externalLink,
  className = "",
  color,
  hoverColor,
  padding,
  opacity,
  margin,
  children,
  width,
  mobileWidth,
  fontSize,
  fontFamily,
  animatedSvg,
  display,
  preventDefault,
  design
}: Props) => {
  const onClickHandler = (event: MouseEvent) => {
    if (preventDefault) {
      return event.preventDefault();
    }
    return null;
  };

  if (internalLink?.slug) {
    const { slug } = internalLink;
    const to = slug?.current === "/" ? "/" : `/${slug?.current}`;

    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        opacity={opacity}
        margin={margin}
        color={color}
        hovercolor={hoverColor}
        width={width}
        mobilewidth={mobileWidth}
        fontFamily={fontFamily}
        fontSize={fontSize}
        animatedsvg={animatedSvg}
        display={display}
        onClick={e => onClickHandler(e)}
      >
        <StyledLinkInner design={design}>
          <LinkChild children={children} linkText={linkText} />
          {design === "primary" && <IconDownCircle />}
        </StyledLinkInner>
      </StyledGatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <A
        href={externalLink ?? "/"}
        className={className}
        target="_blank"
        rel="noreferrer"
        color={color}
        opacity={opacity}
        hovercolor={hoverColor}
        margin={margin}
        display={display}
        width={width}
        onClick={e => onClickHandler(e)}
      >
        <StyledLinkInner design={design}>
          <LinkChild children={children} linkText={linkText} />
          {design === "primary" && <IconDownCircle />}
        </StyledLinkInner>
      </A>
    );
  }

  return null;
};

export default Link;
