import styled from "styled-components";
import { fontWeights, colors, transitionAll, PRIMARY_COLOR } from "@util/constants";

export const HeaderDesktopWrapper = styled.div<{
  designSolid?: boolean
}>`
  position: fixed;
  top:0;
  left:0;
  padding:15px 45px;
  width:100%;
  z-index:20;
  display:grid;
  grid-template-columns: 125px auto;
  grid-gap:15px;
  align-items:center;
  border-bottom:1px solid transparent;
  ${transitionAll}
  ${({ designSolid }) => designSolid && `
  background-color:#fff;
  border-bottom:1px solid #f2f2f2;
  &&& .parentLi-text{
    a{
      color:${PRIMARY_COLOR};
    }
    svg path{
      stroke:${PRIMARY_COLOR};
    }
  }
  &&& .columnLogo {
    svg {
      filter: brightness(0.5);
    }
  }
  `}
`

export const ColumnLogo = styled.div`
  min-height:40px;
`

export const ParentUl = styled.ul`
  text-align:right;
`

export const ParentLi = styled.li<{
  isLastItem: boolean;
  dropDownActive: boolean;
}>`
  text-align:right;
  display:inline-block;
  margin-left:47px;
  font-size:13px;
  font-weight:${fontWeights.bold};
  color:#fff;
  ${({ isLastItem }) => isLastItem && `position:relative;`}
  .parentLi-text{
    color:inherit;
    cursor:pointer;
    ${transitionAll}
    svg{
      margin-left:10px;
      ${transitionAll}
    }
    &:hover{
      opacity:.5;
    }
  }
  &:first-child{
    margin-left:0;
  }
  .this-child{
    position:absolute;
    list-style-type:none;
    z-index:10;
    text-align:left;
    padding:26px 15px;
    background-color:#fff;
    margin-top:20px;
    ${({ isLastItem }) => isLastItem && `
      min-width:170px;
      right:0;
      top:100%;
    `}
    li{
      margin-bottom:15px;
      font-size:13px;
      color:${colors.textBody};
      a:hover{
        opacity:.5;
      }
      &:last-child{
        margin-bottom:0;
      }
    }
  }
  .this-child-hide{
    display:none;
  }
`