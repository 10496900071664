import React from "react";

import { Container, GridContainer } from "@util/standard";
import { Preview } from "@global";
import { Maybe, SanityGridProfile } from "@graphql-types";

interface Props {
  data: Maybe<SanityGridProfile>;
}

export default function PreviewGrid({ data }: Props) {
  if (data == null) return null;
  const { blocks } = data;

  return (
    <Container overflow="hidden">
      {blocks && (
        <GridContainer repeat={3} margin="0 auto" tabletRepeat={1} rowGap={5} columnGap={6}>
          {blocks.map(block => {
            if (block == null) return null;
            const { title, subtitle, image, link, _key } = block;

            return (
              <Preview key={_key} title={title} subtitle={subtitle} link={link} image={image} />
            );
          })}
        </GridContainer>
      )}
    </Container>
  );
}
