import React, { CSSProperties } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { Maybe, SanityImageWithMeta } from "@graphql-types";

const StyledImage = styled(GatsbyImage)<{
  width: string | undefined;
  filter: string | undefined;
  maxwidth: string | undefined;
}>`
  width: ${props => props.width ?? "100%"};
  ${({ filter }) => filter && `filter: ${filter};`}
  ${({ maxwidth }) => maxwidth && `max-width: ${maxwidth};`}
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`;

interface Props {
  data: Maybe<SanityImageWithMeta>;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
  filter?: string;
  maxwidth?: string;
}

const Image = (props: Props) => {
  const { objectFit } = props;
  const imageData = props.data?.asset?.gatsbyImageData;

  if (props.isBackground && imageData) {
    return (
      <BackgroundImageWrapper>
        <StyledImage
          image={imageData}
          style={{ margin: props.margin, height: "100%" }}
          width={props.width}
          filter={props.filter}
          itemProp="image"
          alt={props.data?.altText ?? "bg-image"}
          onClick={props.onClick}
          objectFit={objectFit ?? "cover"}
          maxwidth={props.maxwidth}
        />
      </BackgroundImageWrapper>
    );
  }

  if (imageData) {
    return (
      <StyledImage
        image={imageData}
        style={{ margin: props.margin }}
        width={props.width}
        filter={props.filter}
        itemProp="image"
        alt={props.data?.altText ?? "gatsby-image"}
        onClick={props.onClick}
        objectFit={objectFit ?? "contain"}
        maxwidth={props.maxwidth}
      />
    );
  }

  return null;
};

export default Image;
