import React, { useState, useEffect } from "react";
import OpenCloseIcon from "@shared/sub/openCloseIcon";
import { getHeader } from "@api";
import { IconLogo } from "@util/assets";

import {
  HeaderMobileWrapper,
  MobHeader,
} from "./headerMobile.style";
import HeaderMobileNav from "./headerMobileNav";
import { globalHistory } from '@reach/router'
import { Link } from "@global";

const HeaderMobile = () => {
  const { sanityNavigation } = getHeader();
  if (sanityNavigation == null) return null;
  const { links } = sanityNavigation;
  if (links == null) return null;

  const MobHeaderHeight = 72; // todo: should be dynamic

  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setActiveMenu(false)
      }
    })
  }, [activeMenu])

  return (
    <HeaderMobileWrapper mobHeaderHeight={MobHeaderHeight} activeMenu={activeMenu} >

      <MobHeader>
        <div style={{ visibility: activeMenu ? `hidden` : `visible` }}>
          <Link internalLink={{ slug: { current: '/' } }}>
            <IconLogo />
          </Link>
        </div>

        <div onClick={() => setActiveMenu(!activeMenu)}>
          <OpenCloseIcon open={activeMenu} darkVersion={activeMenu} />
        </div>
      </MobHeader>

      {activeMenu && <HeaderMobileNav />}
    </HeaderMobileWrapper>
  )
}

export default HeaderMobile;