import React from "react";
import { useFooterQuery } from "@api"
import { Link, Image } from "@global"
import { 
    FooterWrapper,
    FooterLogos,
    FooterMainNav,
    ColumnLink,
    KontainerTop,
    KontainerBottom,
  } from "./footer.style"

const Footer = () => {
  const data = useFooterQuery();
  if( data == null) return null;

  const {
    links,
    footerLogo,
    footerLogoSecondary,
    bottomLinks,
  } = data

  return (
    <FooterWrapper>
      <KontainerTop size="default">

        <FooterMainNav>
          {links && links.map( columnLink => {
            if(columnLink == null) return null;
            const { _key, columnTitle } = columnLink;
            const footerNavLinks = (columnLink.links ? columnLink.links : null);
            if (footerNavLinks == null) return null;
            
            return <ColumnLink key={_key} >
              <h3>{ columnTitle }</h3>
              <ul>
                {footerNavLinks.map( footerNavLink => {
                  if(footerNavLink == null) return;

                  return <li key={footerNavLink._key}>
                    <Link
                      {...footerNavLink}
                    />
                  </li>

                })}
              </ul>
            </ColumnLink>
          })}
        </FooterMainNav>

        {(footerLogo || footerLogoSecondary) &&
          <FooterLogos>
            {footerLogo && <Image data={footerLogo} maxwidth="113px" /> }
            {footerLogoSecondary && <Image data={footerLogoSecondary} maxwidth="79px" /> }
          </FooterLogos>
        }
      </KontainerTop>

      {bottomLinks &&
      <KontainerBottom size="default">
        <ul>
          {bottomLinks.map( bottomLink => {
            if(bottomLink == null) return;
            return <li key={bottomLink._key}>
              <Link
                {...bottomLink}
              />
            </li>
          })}
        </ul>
      </KontainerBottom>
      }

    </FooterWrapper>
  )
};

export default Footer;
