import styled from "styled-components";
import { mediaQuery, colors, transitionAll } from "@util/constants";
import { Kontainer } from "@util/standard";
import { DesignGridOutlineComponent } from "@util/types";

export const GridOutlineInner = styled.div<{
  design?: DesignGridOutlineComponent;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid ${colors.border};
  position: relative;
  width: 100%;
  ${({design}) => design === 'minimise-left-column' && `
    grid-template-columns: 350px auto;
  `}
  ${mediaQuery.largeDown} {
    grid-template-columns: 1fr;
  }
  article {
    padding: 80px;
    border-bottom: 1px solid ${colors.border};
    ${mediaQuery.mediumDown} {
      padding: 60px 50px;
    }
  }
  ${mediaQuery.largeUp} {
    article:nth-child(odd){
      border-right:1px solid ${colors.border};
    }
  }
`;

export const GridOutlineKontainer = styled(Kontainer)`
  ${mediaQuery.smallDown} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const LoadMoreKontainer = styled(Kontainer)`
  text-align: center;
  margin-top: 89px;
  ${mediaQuery.mediumDown} {
    margin-top: 30px;
  }
  .grid-outline--load-more-button {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    ${transitionAll};
    cursor: pointer;
    p {
      font-size: 15px;
      margin-bottom: 15px;
    }
    svg {
      circle,
      path {
        stroke: ${colors.textBody};
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const ArticleInner = styled.div<{
  isNews?: boolean;
}>`
  width: 100%;
  max-width: 401px;
  margin: 0 auto;
  ${mediaQuery.wideUp} {
    max-width: 600px;
  }
  ${({ isNews }) => isNews &&
    `
    h3{
      margin-bottom:15px;
    }
    p:not(:last-child){
      margin-bottom:15px;
    }
    .grid-outline--readmore{
      margin-top:15px;
      display:block;
    }
  `
  }
`