import styled from "styled-components";
import { CSSProperties } from "react";

import {
  colors,
  colorsRGB,
  fontWeights,
  PRIMARY_COLOR,
  fontSizes,
  colorsInverse,
  mediaQuery,
  marginBottom,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
} from "./constants";
import { Color, FontWeights, HeadingStringType, KontainerSizeType, MarginBottom } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, containerGap } from "@util/constants";
import { Maybe } from "@graphql-types";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: CSSProperties["textAlign"];
  letterSpacing?: CSSProperties["letterSpacing"];
  margin?: string;
  userSelect?: string;
}>`
  background-color: transparent;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : fontWeights.normal)};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
  display?: CSSProperties["display"];
  width?: string;
}>`
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${props => (props.height ? props.height : `auto`)};
  width: ${props => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${props => props.thickness ?? 3}px solid
    ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${props => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  flex?: CSSProperties["flex"];
  flexGrow?: CSSProperties["flexGrow"];
  flexWrap?: CSSProperties["flexWrap"];
  flexFlow?: CSSProperties["flexFlow"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  justifyContent?: CSSProperties["justifyContent"];
  mobileJustifyContent?: CSSProperties["justifyContent"];
  width?: string;
  maxWidth?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: CSSProperties["display"];
  backgroundSize?: CSSProperties["backgroundSize"];
  position?: CSSProperties["position"];
}>`
  display: ${props => props.display ?? "flex"};
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  border-radius: ${props => props.borderRadius ?? 0}px;

  ${({ position }) => position && `position: ${position};`}
  ${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ marginTop }) => marginTop && `&&& {margin-top: ${marginTop};}`}
  ${({ marginBottom }) => marginBottom && `&&& {margin-bottom: ${marginBottom};}`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}
      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
      ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.tabletRepeat ?? 2}, 1fr);
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${props => props.lineHeight ?? 18}px;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: ${props => props.margin ?? "0"};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: 0;
  }
  &:hover {
    /* color: ${props => (props.hoverColor ? colors[props.hoverColor] : colors.flightBlue)}; */
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${props => props.space ?? 10}px;
`;

export const AbsoluteWrapper = styled.div<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  zIndex?: number;
}>`
  position: absolute;
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;

export const FullHeightContainer = styled(Container)<{
  height?: string | undefined;
  width?: string | undefined;
}>`
  flex-direction: column;
  position: relative;
  width: ${props => props.width ?? "100%"};
  height: ${props => props.height ?? "100vh"};
  overflow: hidden;
`;

export const MobileContainer = styled(Container)`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }
`;

export const DesktopContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const Span = styled.span<{
  fontSize?: number;
  display?: CSSProperties["display"];
  margin?: string;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

interface TheadingDesign {
  design?: HeadingStringType;
  inverse?: boolean;
}

export const headingDesign = (props: TheadingDesign) => {
  if (props?.design === "h1") {
    return `
      font-size:${fontSizes.h1.default}px;
      color:${props.inverse ? colorsInverse.heading : colors.heading};
      font-weight:${fontWeights.extrabold};
      ${mediaQuery.mediumDown}{
        font-size:${fontSizes.h1.mobile}px;
      }
    `;
  }

  if (props?.design === "h2") {
    return `
      font-size:${fontSizes.h2.default}px;
      color:${props.inverse ? colorsInverse.heading : colors.heading};
      font-weight:${fontWeights.extrabold};
      ${mediaQuery.mediumDown}{
        font-size:${fontSizes.h2.mobile}px;
      }
    `;
  }

  if (props?.design === "h3") {
    return `
      font-size:${fontSizes.h3.default}px;
      color:${props.inverse ? colorsInverse.textBody : colors.heading};
      font-weight:${fontWeights.extrabold};
      letter-spacing:0.08em;
      ${mediaQuery.mediumDown}{
        font-size:${fontSizes.h3.mobile}px;
      }
    `;
  }

  if (props?.design === "h4") {
    return `
    font-size:${fontSizes.h4.default}px;
    color:${props.inverse ? colorsInverse.textBody : colors.textBody};
    font-weight:${fontWeights.bold};
    ${mediaQuery.mediumDown}{
      font-size:${fontSizes.h4.mobile}px;
    }
    `;
  }

  if (props?.design === "h5") {
    return `
    font-size:${fontSizes.h5.default}px;
    color:${props.inverse ? colorsInverse.textBody : colors.headingB};
    font-weight:${fontWeights.bold};
    `;
  }

  if (props?.design === "h7") {
    return `
      font-size:${fontSizes.h6.default}px;
      color:${props.inverse ? colorsInverse.heading : colors.heading};
      font-weight:${fontWeights.extrabold};
      letter-spacing:0.08em;
      ${mediaQuery.mediumDown}{
        font-size:${fontSizes.h3.mobile}px;
      }
    `;
  }

  return `
  font-size:${fontSizes.h6.default}px;
  color:${props.inverse ? colorsInverse.textBody : colors.heading};
  font-weight:${fontWeights.bold};
  `;
};

export const HeadingStyle = styled.div<{
  props: TheadingDesign;
}>`
  ${({ props }) => props && headingDesign(props)}
  text-transform:uppercase;
  white-space: pre-wrap;
`;

export const kontainerDesign = (size?: KontainerSizeType) => {

  const defaults = `
    width:100%;
    margin:0 auto;
    padding-left:${containerGap}px;
    padding-right:${containerGap}px;
  `;

  if (size === "wide") {
    const wideGap = 113;
    const wideGapWithoutPadding = wideGap * 2 - containerGap * 2;

    return `
      ${defaults}
      max-width:calc(100% - ${wideGapWithoutPadding}px);
      ${mediaQuery.mediumDown}{
        max-width:100%;
      }
    `;
  }

  // 816px
  if (size === "narrow") {
    const narrowGap = 305;
    const narrowGapWithoutPadding = narrowGap * 2 - containerGap * 2;

    return `
      ${defaults}
      max-width:calc(100% - ${narrowGapWithoutPadding}px);
      ${mediaQuery.wideDown}{
        max-width:100%;
      }
    `;
  }

  // 1009px
  if (size === "default") {
    const defaultGap = 213;
    const defaultGapWithoutPadding = defaultGap * 2 - containerGap * 2;
    return `
      ${defaults}
      max-width:calc(100% - ${defaultGapWithoutPadding}px);
      ${mediaQuery.wideDown}{
        max-width:100%;
      }
    `;
  }

  return null;
};

export const Kontainer = styled(Container)<{
  size?: KontainerSizeType;
  mb?: MarginBottom;
  containerWidth?: Maybe<string>;
}>`
  ${({ containerWidth }) =>
    containerWidth
      ? containerWidth === "default"
        ? `width: 70%; margin: auto;`
        : `width: 90%; margin: auto;`
      : "width: 100%;"}
  ${({ size }) => size && kontainerDesign(size)}    
  ${({ mb }) => mb && `${marginBottom[mb]};`}
`;

export const HoverContainer = styled(Container)<{ hoverEffect?: boolean }>`
  visibility: hidden;
  opacity: 0;
  height: 0px;

  /* ${({ hoverEffect }) => hoverEffect && `visibility: inherit; opacity: 1; height: 50px;`}; */
  transition: all 0.2s;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    opacity: 1;
    height: 50px;
    visibility: inherit;

    .subtitle {
      display: none;
    }
  }
`;
