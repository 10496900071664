import React, { useState, useEffect } from "react";
import { Maybe, SanityNews, SanityBlockContent } from "@graphql-types";
import { Heading, Link, BlockContent } from "@global";
import {
  GridOutlineInner,
  GridOutlineKontainer,
  LoadMoreKontainer,
  ArticleInner
} from "./gridOutlineComponent.style";
import { IconDownCircle } from "@util/assets";
import { isSanityNews, DesignGridOutlineComponent } from "@util/types";

interface Props {
  items?: Array<Maybe<SanityNews> | Maybe<SanityBlockContent> | undefined>;
  amountToDisplay?: number | null;
  showLoadMore?: boolean;
  design?: DesignGridOutlineComponent;
}

function gridOutlineComponent({ items, amountToDisplay, showLoadMore, design }: Props) {
  if (items == null) return null;
  const initialDisplay = amountToDisplay ? amountToDisplay : items.length;

  const [filteredItems, setFilteredItems] = useState(
    items.filter((_item, i) => i < initialDisplay),
  );
  const [cursor, setCursor] = useState(initialDisplay);

  const loadMoreHandler = () => {
    const nextToLoad = cursor + 8;
    setCursor(nextToLoad);
  };

  useEffect(() => {
    setFilteredItems(() => {
      return items.filter((_item, i) => i < cursor);
    });
  }, [cursor]);

  return (
    <>
      <GridOutlineKontainer size="wide">
        <GridOutlineInner design={design}>
          {filteredItems.map(item => {
            if (item == null) return null;

            if (isSanityNews(item)) {
              const { date, description, excerpt, file, title, id } = item;

              return (
                <article key={id}>
                  <ArticleInner isNews={true}>
                    <Heading data={{ design: "h6" }}>{title}</Heading>

                    <p>
                      <strong>{excerpt}</strong>
                    </p>
                    <p>{date}</p>
                    {description && <BlockContent data={description} />}
                    {file && (
                      <span className="grid-outline--readmore">
                        <Link externalLink={file.asset?.url} design="primary">
                          Read More
                        </Link>
                      </span>
                    )}
                  </ArticleInner>
                </article>
              );
            }

            return (
              <article key={item._key}>
                <ArticleInner isNews={false}>
                  <BlockContent data={item} />
                </ArticleInner>
              </article>
            );
          })}
        </GridOutlineInner>
      </GridOutlineKontainer>

      {items.length !== filteredItems.length && showLoadMore && (
        <LoadMoreKontainer size="default">
          <div className="grid-outline--load-more-button" onClick={loadMoreHandler}>
            <p>LOAD MORE</p>
            <span>
              <IconDownCircle />
            </span>
          </div>
        </LoadMoreKontainer>
      )}
    </>
  );
}

export default gridOutlineComponent;
