import React from 'react'
import { Maybe, SanityGoogleMap } from "@graphql-types";
import { Kontainer } from '@util/standard';
import { GoogleMapIframe } from '@global';

interface Props{
  data: Maybe<SanityGoogleMap> | undefined;
}

function googleMapSection({data}: Props) {
  if(data == null) return null;
  const { address } = data
  if(address == null) return null;

  return (
    <section>
      <Kontainer size="narrow">
        <GoogleMapIframe address={address} />
      </Kontainer>
    </section>
  )
}

export default googleMapSection