import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const getPageSlugById = (id :String) => {

  const allPageSlugs: Query = useStaticQuery(graphql`
    {
      allSanityPage {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
    }
  `);

  const nodes = allPageSlugs.allSanityPage.nodes;
  const page = nodes.filter(node => node.id === id);

  return page[0]

}

export default getPageSlugById;
