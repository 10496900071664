import React, { useState } from "react";
import styled from "styled-components";

import { Container, P, AbsoluteWrapper } from "@util/standard";
import { MOBILE_BREAKPOINT, IPAD_PRO_BREAKPOINT } from "@util/constants";
import { Maybe, SanityImageWithMeta, SanityLink } from "@graphql-types";
import { Image, Link } from "@global";
import Heading from "./heading";

interface Props {
  title: Maybe<string> | undefined;
  subtitle: Maybe<string> | undefined;
  image: Maybe<SanityImageWithMeta> | undefined;
  link?: Maybe<SanityLink> | undefined;
  onClick?: () => void;
}

const Wrapper = styled(Container)`
  width: 32.9vw;
  height: 75vh;
  position: relative;
  cursor: pointer;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: 50vh;
    width: 100vw;
  }
`;

const TextContainer = styled(Container)<{ hoverEffect: boolean }>`
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 30px;
  transition: all 0.2s;
  flex-direction: column;

  h3,
  p {
    color: white;
  }

  ${({ hoverEffect }) => hoverEffect && `bottom:60px `};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    bottom: 30px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    bottom: 30px;
    p {
      line-height: 18px;
    }
  }
`;

const Content = ({ title, subtitle, image }: Props) => {
  const [hoverEffectOn, setHoverEffectOn] = useState(false);

  return (
    <Wrapper>
      {image && <Image isBackground data={image} objectFit="cover" filter="brightness(0.7)" />}
      <TextContainer hoverEffect={hoverEffectOn}>
        {title && <Heading data={{design:'h3', tag:'h3'}}>{title.toUpperCase()}</Heading>}
        {subtitle && <P className="subtitle" margin="15px 0 0 0">{subtitle}</P>}
      </TextContainer>
      <AbsoluteWrapper
        left="0"
        right="0"
        bottom="0"
        top="0"
        onMouseEnter={() => setHoverEffectOn(true)}
        onMouseLeave={() => setHoverEffectOn(false)}
      />
    </Wrapper>
  );
};

function Preview(props: Props) {
  const { link } = props;
  if (link) {
    return (
      <Link externalLink={link.externalLink} internalLink={link.internalLink}>
        <Content {...props} />
      </Link>
    );
  }

  //TODO: add on click for person modal
  return <Content {...props} />;
}

export default Preview;
