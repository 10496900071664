import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme } from "@util/types";
import { buttonStyle, colors, MOBILE_BREAKPOINT } from "@util/constants";
import { Maybe, Scalars } from "@graphql-types";
import { Container } from "@util/standard";
import { Loading } from "@global";

const StyledButton = styled.button<{
  theme: any;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
}>`
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: relative;

  padding: ${props => (props.padding ? props.padding : `14px 35px`)};
  color: ${props => props.theme.text};
  border: ${props =>
    props.theme && props.theme.border === "transparent"
      ? "none"
      : `1px solid ${props.theme.border}`};
  box-sizing: border-box;
  border-radius: 50px;
  background-color: ${props => props.theme.bg};
  width: ${props => props.width ?? "fit-content"};
  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  
  &:focus {
    outline: 0;
  }

  overflow: hidden;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  &::after {
    background-color: ${props => props.theme.hoverBg ?? colors.transparent};
    content: "";
    display: block;
    height: 100px;
    width: 100px;
    position: absolute;
    left: -5px;
    top: 0px;
    transform: translate(-100%, -100%);
    border-radius: 0 100% 100% 0;
    box-sizing: border-box;

    transform-origin: bottom left;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  &:hover::after {
    transform: scale(3, 3);
  }

  &:hover {
    border: ${props =>
      props.theme && props.theme.border === "transparent"
        ? "none"
        : `1px solid ${props.theme.hoverBorder}`};
    color: ${props => props.theme.hoverText ?? colors.transparent};
  }

  &[disabled],
  &.disabled,
  &[aria-disabled]{
    pointer-events: none;
    opacity: 0.5;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

const Loader = () => (
  <Container justifyContent="center" alignItems="center">
    <Loading />
    <p style={{ margin: "0 0 0 5px" }}>Loading..</p>
  </Container>
);

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  text: string;
  onClick?: (args?: any) => void;
  minHeight?: number;
  margin?: string;
  padding?: string;
  mobileMargin?: string;
  type?: "button" | "submit" | "reset";
  width?: string;
  external?: Maybe<Scalars["String"]>;
  internal?: Maybe<any>;
  loading?: boolean;
}

const Button = ({ theme = "base", onClick, text, internal, loading, ...rest }: Props) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (internal) {
    const to = () => {
      if (internal?._type === "blog") return `/blog/${internal?.slug?.current}`;
      return internal?.slug?.current ?? internal?.getInTouch?.url;
    };

    return (
      <GatsbyLink to={to()}>
        <StyledButton theme={buttonStyle[theme]} onClick={handleOnClick} {...rest}>
          {loading ? <Loader /> : text}
        </StyledButton>
      </GatsbyLink>
    );
  }

  return (
    <StyledButton theme={buttonStyle[theme]} onClick={handleOnClick} {...rest}>
      {loading ? <Loader /> : text}
    </StyledButton>
  );
};

export default Button;
