import React, { useState } from "react";
import { Container, GridContainer } from "@util/standard";
import { Maybe, SanityPeople } from "@graphql-types";
import { Modal, ModalKontainer, ModalContent, ModalBio } from "./peopleGrid.style";
import { Image, Heading, Preview } from "@global";
import OpenCloseIcon from "@shared/sub/openCloseIcon";
import { useAllPeopleQuery } from "@api";
import { useCheckScreenSize } from "@util/hooks";

export default function PeopleGrid() {
  const { nodes } = useAllPeopleQuery();
  if (nodes == null) return null;

  const [appendModal, setAppendModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [personData, setPersonData] = useState<Maybe<SanityPeople>>(null);

  const showHandler = () => {
    setAppendModal(!appendModal);
    setTimeout(() => setShowModal(!showModal), 100);
    document.body.style.overflow = "hidden";
  };

  const closeHandler = () => {
    setShowModal(!showModal);
    setTimeout(() => setAppendModal(!appendModal), 500);
    document.body.style.overflow = "initial";
  };

  const { isSmallUp } = useCheckScreenSize();

  return (
    <Container overflow="hidden">
      <GridContainer repeat={3} margin="0 auto" tabletRepeat={2} rowGap={5} columnGap={6}>
        {nodes.map(node => {
          if (node == null || node.name == null) return null;
          const { name, position, profileImage, _id } = node;

          return (
            <span
              onClick={() => {
                node.bio && showHandler();
                setPersonData(node);
              }}
              key={_id}
            >
              <Preview title={name} subtitle={position} image={profileImage} />
            </span>
          );
        })}
      </GridContainer>

      {appendModal && personData && (
        <Modal show={showModal} onClick={closeHandler}>
          <ModalKontainer>
            <ModalContent onClick={e => e.stopPropagation()}>
              <span className="modal-icon" onClick={closeHandler}>
                <OpenCloseIcon open={true} darkVersion={true} />
              </span>

              {isSmallUp && (
                <div className="column-image">
                  {personData.profileImage && <Image data={personData.profileImage} />}
                </div>
              )}

              <div className="column-content">
                <div className="modal-text">
                  <Heading data={{ design: "h6", tag: "h3" }}>{personData.name}</Heading>
                  <ModalBio>
                    <p>{personData.bio}</p>
                  </ModalBio>
                </div>
              </div>
            </ModalContent>
          </ModalKontainer>
        </Modal>
      )}
    </Container>
  );
}
