import React from 'react'
import {isSanityFormContact, isSanityFormPermitRequester } from "@util/types";
import { FormContact, FormPermitRequester } from "@global"
import { Maybe, SanityFormContact, SanityFormPermitRequester } from "@graphql-types";

interface Props {
  data: Maybe<SanityFormContact> | Maybe<SanityFormPermitRequester> | undefined;
}

function formSelector({data}: Props) {
  if(data == null) return null;

  return (
    <>
      {isSanityFormContact(data) && <FormContact data={data} />}
      {isSanityFormPermitRequester(data) && <FormPermitRequester data={data} />}
    </>
  )
}

export default formSelector