import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import Header from "./header/header";
import Footer from "./footer/footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&amp;display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
}

export default Layout;
