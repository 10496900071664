import React from "react";
import { Maybe, SanityForms } from "@graphql-types";
import { FormSelector } from "@global"
import { FormSectionWrapper } from "./formSection.style";
import { Kontainer } from "@util/standard";

interface Props {
  data: Maybe<SanityForms> | undefined;
}

function formSection({data}: Props) {
  if(data == null) return null;
  
  const { chooseForm } = data
  
  return (
    <FormSectionWrapper>
      <Kontainer size="default">
        <FormSelector data={chooseForm} />
      </Kontainer>
    </FormSectionWrapper>
  )
}

export default formSection