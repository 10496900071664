import React from "react";
import { Maybe, SanityGridText } from "@graphql-types";
import { GridTextWrapper, BlockKontainer } from './gridText.style';
import { BlockContent } from "@global";

interface Props {
  data: Maybe<SanityGridText> | undefined;
}

function gridText({ data }: Props) {
  if(data == null) return null;
  const { blocks } = data;
  if(blocks == null) return null;

  return (
    <GridTextWrapper>
      <BlockKontainer size="default">
        {blocks && blocks.map( block => 
          (block && <BlockContent key={block._key} data={block.descriptionGridItemText} />)
        )}
      </BlockKontainer>
    </GridTextWrapper>
  )
}

export default gridText