import React from 'react'
import { Maybe, SanityStaticImage } from "@graphql-types";
import { Kontainer } from '@util/standard';
import { Image, Link } from '@global';

interface Props{
  data: Maybe<SanityStaticImage> | undefined;
}

function staticImage({data}: Props) {
  if(data == null) return null;

  const { image, containerWidth, linkImageToFile } = data;
  
  return (
    <section>
      <Kontainer size={containerWidth ? containerWidth : "default"} >
        {image && !linkImageToFile && <Image data={image} />} 
        {image && linkImageToFile &&
          <Link externalLink={linkImageToFile.asset?.url} width="100%">
            <Image data={image} />
          </Link>
        }
      </Kontainer>
    </section>
  )
}

export default staticImage