import React from 'react'
import styled from "styled-components";
import { absoluteFill } from "@util/constants";
import { getSiteConfig } from "@api"

interface Props{
  address: string;
  zoom?: number;
}

const StyledMapIframeWrapper = styled.div`
  padding-top:56.25%;
  position:relative;
  width:100%;
  overflow:hidden;
  iframe{
    ${absoluteFill}
    border:0;
  }
`

function googleMapIframe({address, zoom}: Props) {
  if(address == null) return null;
  const data = getSiteConfig();
  if( data == null) return null;
  
  const { googleApiKey } = data;

  const iframeSrc = `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${encodeURIComponent(address)}&zoom=${zoom ? zoom : 10}`

  return (
    <StyledMapIframeWrapper>
      <iframe
        loading="lazy"
        src={iframeSrc}>
      </iframe>
    </StyledMapIframeWrapper>
  )
}

export default googleMapIframe