import React, { useState } from "react";
import { getHeader } from "@api";
import { IconDown } from "@util/assets";
import { Link } from "@global";
import {
  MobNav,
  ParentUl,
  ParentLi
} from "./headerMobile.style";

const headerMobileNav = () => {

  const { sanityNavigation } = getHeader();
  if (sanityNavigation == null) return null;
  const { links } = sanityNavigation;
  if ( links == null ) return null;

  return (
    <MobNav>
    <ParentUl>
      {links.map((link) => {
        if (link == null) return null;
        const { parentLink, childLinks, _key } = link;
        if (parentLink == null) return null;
        const [childDropDown, setChildDropDown] = useState(false);

        return (
          <ParentLi
            key={_key}
            dropDownActive={childDropDown}
            className="parentLi"
          >
            <span
              className="parentLi-text"
              onClick={() => setChildDropDown(!childDropDown)}
            >
              <Link
                {...parentLink}
                preventDefault={childLinks && childLinks.length ? true : false}
              />
              {childLinks && childLinks.length !== 0 && <IconDown /> }
            </span>

            {childLinks && childLinks.length !== 0 && (
              <div className={`this-child ${childDropDown ? `this-child-active` : ``}`} >
                <h3 onClick={() => setChildDropDown(!childDropDown)}><IconDown /> <span>{parentLink.linkText}</span></h3>
                <ul>
                  {childLinks.map(childLink => {
                    if(childLink == null) return null;
                    const { _key } = childLink;
                    return (
                      <li key={_key}>
                        <Link
                          {...childLink}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </ParentLi>
        );
      })}
    </ParentUl>
  </MobNav>
  )
}

export default headerMobileNav