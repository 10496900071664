import styled from "styled-components";
import { absoluteFill, fontWeights, mediaQuery, paddingTopBottom } from "@util/constants";
import { Kontainer } from "@util/standard";
import { PaddingTopBottom } from "@util/types";


export const FullWidthImageWithTextWrapper = styled.section<{
  padTopBottom?: PaddingTopBottom
}>`
  position: relative;
  width:100%;
  ${({ padTopBottom }) => padTopBottom && paddingTopBottom[padTopBottom] }
`

export const ImageWrapper = styled.div`
  ${absoluteFill};
  z-index:-1;
`

export const RowContent = styled.div`
  display:block;
  width:100%;
`

export const Description = styled.div<{
  pushContentLeft?: string;
}>`
  text-align:center;
  width:100%;
  max-width:618px;
  margin:0 auto;
  ${({pushContentLeft}) => pushContentLeft === "push-content-left" && `
    ${mediaQuery.smallUp}{
      text-align:left;
      margin:0 auto 0 0;
    }
  `}
`

export const BlockItem = styled.div`
  text-align:center;
  color:#fff;
  h3{
    font-weight:${fontWeights.bold};
    margin-bottom:15px;
    font-size:40px;
    ${mediaQuery.largeDown}{
      font-size:20px;
    }
  }
`

export const KontainerBlock = styled(Kontainer)<{
  blockLength?: number;
}>`
  display:grid;
  margin-top:60px;
  grid-gap:60px;
  ${({ blockLength }) => blockLength && `grid-template-columns:repeat(${blockLength}, 1fr);`}
  ${mediaQuery.mediumDown}{
    grid-gap:30px;
    grid-template-columns:1fr 1fr;
  }
  ${mediaQuery.smallDown}{
    grid-template-columns:1fr;
  }
`


