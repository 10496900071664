import React, { useState } from "react";
import { Maybe, SanityVideo } from "@graphql-types";
import {
  ContentWrapper,
  VideoClip,
  VideoWrapper,
  Content,
  ContentKontainer,
} from "./videoSection.style";
import { Video, Image } from "@global";
import { HeadingStyle } from "@util/standard";
import { IconPlay, IconStop } from "@util/assets";

interface Props {
  data: Maybe<SanityVideo> | undefined;
}

function videoSection({ data }: Props) {
  if (data == null) return null;

  const { backgroundImage, title, descriptionText, vimeoID } = data;

  const [playerPlay, setPlayerPlay] = useState(false);

  return (
    <VideoWrapper isPlaying={playerPlay}>
      {backgroundImage && <Image isBackground={true} data={backgroundImage} />}

      {vimeoID && (
        <VideoClip>
          <Video
            id={vimeoID}
            url={vimeoID}
            volume={true}
            autoPlayOnLoad={false}
            isVisible={playerPlay}
            foregroundOpen={playerPlay}
          />
        </VideoClip>
      )}

      <span className="video-section-controls" onClick={() => setPlayerPlay(!playerPlay)}>
        {!playerPlay ? <IconPlay /> : <IconStop />}
      </span>

      {(title || descriptionText) && !playerPlay && (
        <ContentWrapper>
          <ContentKontainer size="wide">
            <Content>
              {title && (
                <HeadingStyle props={{ design: "h2", inverse: true }} as="h2">
                  {title}
                </HeadingStyle>
              )}
              {descriptionText && <p style={{ fontSize: 16 }}>{descriptionText}</p>}
            </Content>
          </ContentKontainer>
        </ContentWrapper>
      )}
    </VideoWrapper>
  );
}

export default videoSection;
