import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const NewsQuery = () => {
  const { allSanityNews }: Query = useStaticQuery(graphql`
    {
      allSanityNews(sort: { order: DESC, fields: date }) {
        nodes {
          _type
          description {
            ...sanityBlockContent
          }
          excerpt
          file {
            _key
            asset {
              url
            }
          }
          id
          title
          date(formatString: "DD MMMM, YYYY")
        }
      }
    }
  `);

  return allSanityNews;
};

export default NewsQuery;
