export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#24468E",
  textBody: "#40444A",
  heading: "rgba(6, 15, 37, 0.21)",
  headingB: "rgba(64, 68, 74, 0.7)",
  border: `rgba(64, 68, 74, 0.3)`,
  lightGrey: `#F9F9F9`
};

export const colorsInverse = {
  textBody: "#ffffff",
  heading: "rgba(255, 255, 255, 0.3)",
  headingB: "rgba(64, 68, 74, 0.7)",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 900,
};

export const PRIMARY_COLOR = "#0D162F";
export const SECONDARY_COLOR = colors.black;

export const FONT_BODY = "'Raleway'";
export const FONT_BODY_WEIGHT = fontWeights.normal;
export const FONT_HEADER = "'Raleway'";
export const FONT_HEADER_WEIGHT = fontWeights.bold;

export const fontDefault = 16;

export const fontSizes = {
  p: { default: fontDefault, mobile: fontDefault },
  h1: { default: 90, mobile: 35 },
  h2: { default: 40, mobile: 26 },
  h3: { default: 30, mobile: 20 },
  h4: { default: 25, mobile: 17 },
  h5: { default: 20, mobile: fontDefault },
  h6: { default: 20, mobile: fontDefault },
  span: { default: fontDefault, mobile: fontDefault },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.transparent}`,
    text: `${colors.flightBlue}`,
    border: `${colors.flightBlue}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.flightBlue}`,
  },
};

export const absoluteFill = `
  position:absolute;
  height:100%;
  width:100%;
  left:0;
  top:0;
`;

export const breakPoint = {
  small: MOBILE_BREAKPOINT,
  medium: IPAD_BREAKPOINT,
  large: TABLET_BREAKPOINT,
  wide: LAPTOP_BREAKPOINT,
};

export const mediaQueryWidths = {
  smallDown: `(max-width: ${breakPoint.small}px)`,
  smallUp: `(min-width: ${breakPoint.small + 1}px)`,
  mediumDown: `(max-width: ${breakPoint.medium}px)`,
  medium: `(min-width: ${breakPoint.medium + 1}px) and (max-width: ${breakPoint.large}px)`,
  mediumUp: `(min-width: ${breakPoint.medium + 1}px)`,
  largeDown: `(max-width: ${breakPoint.large}px)`,
  large: `(min-width: ${breakPoint.large + 1}px) and (max-width: ${breakPoint.wide}px)`,
  largeUp: `(min-width: ${breakPoint.large + 1}px)`,
  wideDown: `(max-width: ${breakPoint.wide}px)`,
  wideUp: `(min-width: ${breakPoint.wide + 1}px)`,
};

const m = `@media only screen and`;
export const mediaQuery = {
  smallDown: `${m} ${mediaQueryWidths.smallDown}`,
  smallUp: `${m} ${mediaQueryWidths.smallUp}`,
  mediumDown: `${m} ${mediaQueryWidths.mediumDown}`,
  medium: `${m} ${mediaQueryWidths.medium}`,
  mediumUp: `${m} ${mediaQueryWidths.mediumUp}`,
  largeDown: `${m} ${mediaQueryWidths.largeDown}`,
  large: `${m} ${mediaQueryWidths.large}`,
  largeUp: `${m} ${mediaQueryWidths.largeUp}`,
  wideDown: `${m} ${mediaQueryWidths.wideDown}`,
  wideUp: `${m} ${mediaQueryWidths.wideUp}`,
};

export const space = {
  none: 0,
  tiny: 5,
  xxs: 15,
  xs: 30,
  s: 50,
  m: 75,
  l: 100,
  xl: 130,
  xxl: 175,
  xxxl: 300
};

export const marginBottom = {
  none: `margin-bottom: ${space.none}px`,
  tiny: `margin-bottom: ${space.tiny}px`,
  xxs: `margin-bottom: ${space.xxs}px`,
  xs: `margin-bottom: ${space.xs}px`,
  s: `margin-bottom: ${space.s}px`,
  m: `margin-bottom: ${space.m}px`,
  l: 
  `margin-bottom: ${space.l}px;
  ${mediaQuery.mediumDown}{
    margin-bottom: ${space.s}px;
  }
  `,
  xl: `
  margin-bottom: ${space.xl}px;
  ${mediaQuery.mediumDown}{
    margin-bottom: ${space.s}px;
  }
  `,
  xxl: `
  margin-bottom: ${space.xxl}px;
  ${mediaQuery.mediumDown}{
    margin-bottom: ${space.m}px;
  }
  ${mediaQuery.smallDown}{
    margin-bottom: ${space.s}px;
  }
  `,
};

export const transitionAll = `
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
`

export const paddingTopBottom = {
  none: `padding-top:${space.none}px;padding-bottom:${space.none}px`,
  xxs: `padding-top:${space.xxs}px;padding-bottom:${space.xxs}px`,
  xs: `padding-top:${space.xs}px;padding-bottom:${space.xs}px`,
  s: `padding-top:${space.s}px;padding-bottom:${space.s}px`,
  m: `padding-top:${space.m}px;padding-bottom:${space.m}px`,
  l: `
  padding-top:${space.l}px;
  padding-bottom:${space.l}px;
  ${mediaQuery.mediumDown}{
    padding-top:${space.s}px;
    padding-bottom:${space.s}px;
  }
  `,
  xl: `
  padding-top:${space.xl}px;
  padding-bottom:${space.xl}px;
  ${mediaQuery.mediumDown}{
    padding-top:${space.s}px;
    padding-bottom:${space.s}px;
  }
  `,
  xxl: `
  padding-top:${space.xxl}px;
  padding-bottom:${space.xxl}px;
  ${mediaQuery.mediumDown}{
    padding-top:${space.m}px;
    padding-bottom:${space.m}px;
  }
  `,
  xxxl: `
  padding-top:${space.xxxl}px;
  padding-bottom:${space.xxxl}px;
  ${mediaQuery.largeDown}{
    padding-top:${space.xxl}px;
    padding-bottom:${space.xxl}px;
  }
  ${mediaQuery.mediumDown}{
    padding-top:${space.l}px;
    padding-bottom:${space.l}px;
  }
  `,
}

export const animation = {
  fadeIn: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  fadeOut: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  slideInLeft: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(-200px)", opacity: 0 },
  },
  slideInRight: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(200px)", opacity: 0 },
  },
  slideInTop: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(-200px)", opacity: 0 },
  },
  slideInBottom: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(50px)", opacity: 0 },
  },
  rotateLeft: {
    to: { transform: "rotateZ(0deg)" },
    from: { transform: "rotateZ(360deg)" },
  },
  rotateRight: {
    to: { transform: "rotateZ(360deg)" },
    from: { transform: "rotateZ(0deg)" },
  },
  noAnimation: {
    to: { opacity: 1 },
    from: { opacity: 1 },
  },
};

export const containerMaxWidth = {
  narrow: 816,
  default: 1009
}

export const containerGap = 56;

export const linear = {
  dark:`linear-gradient(89.55deg, rgba(6, 15, 37, 0.385) 0.04%, rgba(155, 169, 203, 0.025) 99.09%);`,
  darker:`linear-gradient(89.55deg, rgba(6, 15, 37, 0.693) 23.08%, rgba(155, 169, 203, 0.045) 99.09%);`
}