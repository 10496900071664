import styled from "styled-components";
import { absoluteFill, fontWeights, mediaQuery, transitionAll } from "@util/constants";
import { Kontainer } from "@util/standard";

export const VideoWrapper = styled.section<{
  isPlaying: boolean;
}>`
  position:relative;
  width:100%;
  padding-top:56.25%;
  ${mediaQuery.mediumDown}{
    padding-top:unset;
    min-height:450px;
  }
  ${mediaQuery.wideUp}{
    padding-top:unset;
    min-height:800px;
  }
  .video-section-controls{
    position:absolute;
    z-index:10;
    top:calc(50% - 37px);
    left:calc(50% - 37px);
    ${transitionAll}
    cursor:pointer;
    &:hover{
      opacity:0.5;
    }
    ${mediaQuery.largeDown}{
      top:30px;
      left:unset;
      right:30px;
      svg{
        height:50px;
        width:50px;
      }
    }
  }
  .gatsby-image-wrapper{
    ${transitionAll};
    z-index:5;
    opacity:1;
  }
  ${({ isPlaying }) => isPlaying && `
    .gatsby-image-wrapper{
      opacity:0;
    }
    .video-section-controls{
      top:30px;
      left:unset;
      right:30px;
    }
  `}
`

export const VideoClip = styled.div`
  ${absoluteFill};
  z-index:-1;
`

export const ContentWrapper = styled.div`
  ${absoluteFill};
  z-index
`

export const ContentKontainer = styled(Kontainer)`
  display:flex;
  align-items:flex-end;
  height:100%;
  ${mediaQuery.largeDown}{
    align-items:center;
  }
`

export const Content = styled.div`
  margin-bottom:121px;
  width:100%;
  ${mediaQuery.largeDown}{
    margin-bottom:0;
    padding:15px 0;
    text-align:center;
  }
  h2{
    font-size:70px;
    max-width:350px;
    width:100%;
    margin-bottom:30px;
  }
  p{
    font-size:25px;
    font-weight:${fontWeights.bold};
    color:#fff;
    max-width:477px;
    width:100%;
  }
  ${mediaQuery.largeDown}{
    margin-bottom:0;
    padding:15px 0;
    text-align:center;
    h2,p{
      max-width:none;
    }
    h2{
      margin-bottom:15px;
      font-size:45px;
    }
  }
`

export const IconPlayWrapper = styled.span`
  position:absolute;
  z-index:10;
  top:calc(50% - 37px);
  left:calc(50% - 37px);
  ${transitionAll}
  cursor:pointer;
  &:hover{
    opacity:0.5;
  }
  ${mediaQuery.largeDown}{
    top:30px;
    left:unset;
    right:30px;
    svg{
      height:50px;
      width:50px;
    }
  }
`
