import React from "react";
import { Maybe, SanityGridOutline } from "@graphql-types";
import { GridOutlineComponent } from "@global";

interface Props {
  data: Maybe<SanityGridOutline> | undefined;
}

function gridOutline({ data }: Props) {
  if (data == null) return null;
  const { design, blocks } = data;

  const blocksArray = blocks?.map(block => (
    {
      ...block?.gridOutlineItemDescription,
      _key: block?._key
    }
  ));
  
  return (
    <section>
      <GridOutlineComponent items={blocksArray} design={design} />
    </section>
  );
}

export default gridOutline;
