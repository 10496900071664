import React, { ReactNode } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated, SpringConfig } from "react-spring";
import { AnimationType } from "@util/types";

interface Props {
  children: ReactNode;
  animationType: AnimationType;
  loop?: boolean;
  delay?: number;
  disablePartialVisibility?: boolean;
  disableVisibilitySensor?: boolean;
  config?: Partial<SpringConfig>;
}

function AnimationContainer(props: Props) {
  const {
    children,
    animationType,
    loop,
    delay,
    disablePartialVisibility,
    disableVisibilitySensor,
    config,
  } = props;

  return (
    <VisibilitySensor partialVisibility={!disablePartialVisibility}>
      {({ isVisible }) => (
        <Spring
          config={config}
          to={isVisible || disableVisibilitySensor ? animationType.to : null}
          from={animationType.from ? animationType.from : null}
          loop={loop}
          delay={delay ? delay : 0}
        >
          {styles => <animated.div style={{ ...styles, height: "100%" }}>{children}</animated.div>}
        </Spring>
      )}
    </VisibilitySensor>
  );
}

export default AnimationContainer;
