import styled, { createGlobalStyle } from "styled-components";

import { MOBILE_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights, PRIMARY_COLOR, FONT_BODY, FONT_BODY_WEIGHT, FONT_HEADER, FONT_HEADER_WEIGHT } from "@util/constants";

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html{
    scroll-behavior:smooth;
  }
  body {
    margin: 0px;
    font-family: ${FONT_BODY}, sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${FONT_BODY_WEIGHT};
    color: ${PRIMARY_COLOR};
    line-height: 1.6;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  body,
  input,
  textarea,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button,
  html input {
    &[disabled] {
      cursor: default;
    }
  }

  input {
    &[type="search"],
    &[type="number"],
    &[type="email"],
    &[type="password"] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  textarea {
    overflow: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${FONT_HEADER}, sans-serif;
    font-weight: ${FONT_HEADER_WEIGHT};
    line-height: 1em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin:0;

    a {
      color: inherit;
      text-decoration: none;
      font-weight: inherit;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease all;

    &:visited {
      color: currentColor;
    }

  }

  p,ul{
    margin:0;
    padding:0;
  }

  span{
    line-height:1em;
  }

  section{
    width:100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    body {
      font-size: ${fontSizes.p.mobile}px;
    }
  }

`;

export const Main = styled.main<{}>``;
