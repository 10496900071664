import styled from "styled-components";
import { mediaQuery, absoluteFill, transitionAll, containerMaxWidth, containerGap } from "@util/constants";
import { Kontainer } from "@util/standard";

export const Modal = styled.div<{
  show?:boolean;
  onClick?:(e: Event) => void;
}>`
  ${absoluteFill}
  position:fixed;
  background-color:rgba(0,0,0,.5);
  z-index:50;
  display:flex;
  opacity:0;
  overflow-y:auto;
  ${transitionAll}
  ${({ show }) => show && `
    opacity:1;
  `}
  .column-image{
    ${mediaQuery.mediumDown}{
      display:flex;
      align-items:flex-start;
    }
  }
`

export const ModalKontainer = styled(Kontainer)`
  margin:100px auto 0 auto;
  display:block;
  max-width:1266px;
  padding:0 ${containerGap}px;
  ${mediaQuery.mediumDown}{
    padding-left:15px;
    padding-right:15px;
  }
`

export const ModalContent = styled.article<{
  onClick?:(e: Event) => void;
}>`
  background:white;
  display:grid;
  grid-template-columns:1fr 1fr;
  width:100%;
  position:relative ;
  ${mediaQuery.mediumDown}{
    grid-template-columns:1fr 4fr;
  }
  ${mediaQuery.smallDown}{
    grid-template-columns:1fr;
  }
  .modal-icon{
    position:absolute;
    top:35px;
    right:30px;
    &:hover{
      opacity:0.5;
    }
  }
  .column-content{
    display:flex;
    align-items:center;
    .modal-text{
      padding:30px 60px;
      ${mediaQuery.mediumDown}{
        padding:60px 30px;
      }
      h3{
        margin-bottom:15px;
      }
    }
  }
`

export const ModalBio = styled.div`
  ${mediaQuery.mediumUp}{
    overflow-y:auto;
    overflow-x:hidden;
    /* max-height:350px; */
  }
`