import React from "react";
import { HeadingStyle } from "@util/standard";
import { PropsHeading } from "@util/types";

interface Props {
  data: PropsHeading;
  children: any;
  inverse?: boolean;
}

const Heading = ({ data, children, inverse }: Props) => {
  if (data == null) {
    return null;
  }

  const tag = data.tag ? data.tag : "h3";
  const design = data.design ? data.design : "h3";

  return (
    <HeadingStyle props={{ design: design, inverse: inverse ? true : false }} as={tag}>
      {children}
    </HeadingStyle>
  );
};

export default Heading;
