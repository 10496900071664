import React from 'react'
import { Maybe, SanityFormContact } from "@graphql-types";
import JotformEmbed from "react-jotform-embed";
import { Heading } from "@global"
import styled from "styled-components";
import { marginBottom } from "@util/constants"

interface Props {
  data: Maybe<SanityFormContact> | undefined;
}

const FormContactWrapper = styled.div`
  width:100%;
  .form-header{
    ${marginBottom.m}
  }

  iframe{
    width:100%;
  }
`

function formContact({data}: Props) {
  if(data == null) return null;

  return (
    <FormContactWrapper>
      <div className="form-header">
        <Heading data={{design: "h2", tag: "h2"}}>{data.title}</Heading>
      </div>
      <JotformEmbed src={data.formID} />
    </FormContactWrapper>
  )
}

export default formContact