import React from "react";
import { Maybe, SanityFullWidthImageWithText } from "@graphql-types";
import { Image, BlockContent } from "@global";
import { Kontainer } from "@util/standard"

import { FullWidthImageWithTextWrapper, RowContent, ImageWrapper, Description, BlockItem, KontainerBlock } from "./fullWidthImageWithText.style";
interface Props {
  data: Maybe<SanityFullWidthImageWithText> | undefined;
}

const fullWidthImageWithText = ({data}: Props) => {
  if (data == null) return null;
  
  const {
    backgroundImage,
    blocks,
    description,
    design,
    paddingTopBottom
  } = data

  const pushContentLeft = {
    pushContentLeft: (design ? design : 'default')
  }

  return(
    <FullWidthImageWithTextWrapper padTopBottom={paddingTopBottom}>
      {backgroundImage &&
      <ImageWrapper>
        <Image isBackground={true} data={backgroundImage}  filter="brightness(50%)" />
      </ImageWrapper>
      }
      <RowContent>
        <Kontainer size="default">
          <Description {...pushContentLeft} >
            <BlockContent data={description} inverse={true} />
          </Description>
        </Kontainer>

        {blocks && 
        <KontainerBlock size={blocks.length === 4 ? "wide" : "default"} blockLength={blocks.length}>
          {blocks.map( block => {
            if(block == null) return null

            return <BlockItem key={block._key}>
              {block.title && <h3>{block.title}</h3>}
              {block.description && <p>{block.description}</p>}
            </BlockItem>
          })}
        </KontainerBlock>
        }
      </RowContent>
      
    </FullWidthImageWithTextWrapper>
    )
}

export default fullWidthImageWithText;