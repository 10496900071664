import React from "react";
import { Maybe, SanityGridOutlineNews } from "@graphql-types";
import { useNewsQuery } from "@api";
import { GridOutlineComponent } from "@global";

interface Props {
  data: Maybe<SanityGridOutlineNews> | undefined;
}

function gridOutlineNews({ data }: Props) {
  if (data == null) return null;
  const allNewsData = useNewsQuery();
  if (allNewsData == null) return null;

  const { amountOfArticlesToDisplay, displayLoadMore } = data;

  return (
    <section style={{ width: "100%" }}>
      <GridOutlineComponent
        items={allNewsData.nodes}
        showLoadMore={displayLoadMore ? displayLoadMore : false}
        amountToDisplay={amountOfArticlesToDisplay}
      />
    </section>
  );
}

export default gridOutlineNews;
