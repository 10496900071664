import styled from "styled-components";
import { mediaQuery } from "@util/constants";
import { Kontainer } from "@util/standard";

export const GridTextWrapper = styled.section`
  width:100%;
`
export const BlockKontainer = styled(Kontainer)`
  display:grid;
  grid-template-columns:repeat(3, 1fr);
  column-gap:30px;
  grid-row-gap: 60px;
  ${mediaQuery.largeDown}{
    grid-template-columns:1fr 1fr;
  }
  ${mediaQuery.mediumDown}{
    grid-template-columns:1fr;
    grid-row-gap: 30px;
  }
`