import React, { CSSProperties } from "react";
import styled from "styled-components";

import { BlockContent, Image } from "@global";
import { Maybe, SanityLeftRightTextItem } from "@graphql-types";
import { LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { useCheckScreenSize } from "@util/hooks";

const MainContainer = styled(Container)<{
  reverse: Maybe<boolean> | undefined;
}>`
  width: 100%;
  height: 100%;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : props.reverse ? "row-reverse" : "row"};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0px;
    height: 100%;
  }
`;

const BlockContainer = styled(Container)`
  width: 70%;
  margin: 0 auto;
  position: relative;
  flex-direction: column;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const MediaContainer = styled(Container)`
  position: relative;
  margin: 0px;
  width: 100%;
  ${({ flexDirection }) => flexDirection && `height: 50%;`}

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    ${({ flexDirection }) => flexDirection && `height: 40%;`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: initial;
  }
`;

interface Props {
  data: Maybe<SanityLeftRightTextItem> | undefined;
  flexDirection?: CSSProperties["flexDirection"];
}

export default function LeftRightText({ data, flexDirection }: Props) {
  if (data == null) return null;
  const { swapColumns, image, description } = data;

  const { isMediumDown, isMediumUp } = useCheckScreenSize();

  const flexDirect = (flexDirection ? flexDirection : (isMediumDown ? 'column' : flexDirection))

  return (
    <MainContainer flexDirection={flexDirect} reverse={swapColumns}>
      {image && isMediumUp && (
        <MediaContainer flexDirection={flexDirection}>
          <Image data={image} />
        </MediaContainer>
      )}

      {image && isMediumDown && (
        <BlockContainer>
          <Image data={image} />
        </BlockContainer>
      )}
    
      <ContentContainer>
        <BlockContainer>
          <BlockContent data={description} />
        </BlockContainer>
      </ContentContainer>
    </MainContainer>
  );
}
