import { Dispatch, SetStateAction } from "react";

import {
  SanityContent,
  SanityGridProfile,
  SanityGridProfilePeople,
  SanityLeftRightText,
  SanityFullWidthImageWithText,
  SanityVideo,
  SanityGridText,
  SanityStaticImage,
  SanityGridOutlineNews,
  SanityGridOutline,
  SanityNews,
  SanityGoogleMap,
  SanityForms,
  SanityFormContact,
  SanityFormPermitRequester,
  Maybe
} from "@graphql-types";
import {
  assets,
  buttonStyle,
  colors,
  colorsRGB,
  fontWeights,
  marginBottom,
  paddingTopBottom,
} from "./constants";

import { SanityImage } from "@graphql-types";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;
export type MarginBottom = keyof typeof marginBottom;
export type PaddingTopBottom = keyof typeof paddingTopBottom;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export interface ScreenSize {
  isSmallDown: boolean;
  isSmallUp: boolean;
  isMediumDown: boolean;
  isMedium: boolean;
  isMediumUp: boolean;
  isLargeDown: boolean;
  isLarge: boolean;
  isLargeUp: boolean;
  isWideDown: boolean;
  isWideUp: boolean;
}

export type HeadingStringType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "h7";

export type DesignGridOutlineComponent = 'default' | 'minimise-left-column' | undefined | null; 

export interface PropsHeading {
  design: HeadingStringType;
  tag?: HeadingStringType;
  text?: string;
}

export type Animation = {
  transform?: string;
  opacity?: number;
};

export type AnimationType = {
  to?: Animation;
  from?: Animation;
};

export type KontainerSizeType = "wide" | "narrow" | "default" | Maybe<string>;

export type LogoType = "light" | "dark";

export function isSanityContent(data: any): data is SanityContent {
  return data._type === "content";
}

export function isSanityLeftRightText(data: any): data is SanityLeftRightText {
  return data._type === "leftRightText";
}

export function isFullWidthImageWithText(data: any): data is SanityFullWidthImageWithText {
  return data._type === "fullWidthImageWithText";
}

export function isSanityGridProfile(data: any): data is SanityGridProfile {
  return data._type === "gridProfile";
}

export function isSanityGridProfilePeople(data: any): data is SanityGridProfilePeople {
  return data._type === "gridProfilePeople";
}

export function isSanityVideo(data: any): data is SanityVideo {
  return data._type === "video";
}

export function isSanityGridText(data: any): data is SanityGridText {
  return data._type === "gridText";
}

export function isSanityStaticImage(data: any): data is SanityStaticImage {
  return data._type === "staticImage";
}

export function isSanityGridOutlineNews(data: any): data is SanityGridOutlineNews {
  return data._type === "gridOutlineNews";
}

export function isSanityGridOutline(data: any): data is SanityGridOutline {
  return data._type === "gridOutline";
}

export function isSanityNews(data: any): data is SanityNews {
  return data._type === "news";
}

export function isSanityGoogleMap(data: any): data is SanityGoogleMap {
  return data._type === "googleMap";
}

export function isSanityForms(data: any): data is SanityForms {
  return data._type === "forms";
}

export function isSanityFormContact(data: any): data is SanityFormContact {
  return data._type === "formContact";
}

export function isSanityFormPermitRequester(data: any): data is SanityFormPermitRequester {
  return data._type === "formPermitRequester";
}
