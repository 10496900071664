import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const AllSanityFileAsset = (id? :String) => {

  const { allSanityFileAsset }: Query = useStaticQuery(graphql`
    {
      allSanityFileAsset {
        totalCount
        nodes {
          url
          _id
        }
      }
    }
  `);

  if(allSanityFileAsset == null) return null;

  const { nodes } = allSanityFileAsset;
  const fileById = nodes.filter(node => node._id === id);
  const { url } = fileById[0]

  return id ? url : allSanityFileAsset;

}

export default AllSanityFileAsset;