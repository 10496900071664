import React, { createElement } from "react";
import PortableText from "@sanity/block-content-to-react";
import styled from "styled-components";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { P } from "@util/standard";
import { Heading, Link } from "@global";
import { PropsHeading } from "@util/types";
import { useFileAssetQuery, getPageSlugById } from "@api"
import { colorsInverse, transitionAll } from "@util/constants";

const BlockContentStyle = styled.div<{
  inverse?: boolean;
}>`
  ${({ inverse }) => inverse && `color: ${colorsInverse.textBody};`}
  ul {
    padding-left: 30px;
  }
  a{
    text-decoration:underline;
    ${transitionAll}
    &:hover{
      opacity:0.5;
    }
  }
`;

const BlockContentButton = styled.div`
  a{
    text-decoration:none;
  }
`

const BlockContentRowStyle = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin: 0;
  }
`;

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
  inverse?: boolean;
}

const BlockContent = ({ data, inverse }: Props) => {
  if (data == null) return null;

  const { _rawBlockContent } = data;

  if (_rawBlockContent == null) return null;

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, { style: { margin: "revert" } }, props.children);
    }
    return (
      <BlockContentRowStyle>
        <P>{props.children}</P>
      </BlockContentRowStyle>
    );
  };

  const heading = (props: any) => {
    const data: PropsHeading = {
      design: props.node.design,
      tag: props.node.tag,
      text: props.node.text,
    };

    return (
      <BlockContentRowStyle>
        <Heading data={data} inverse={inverse}>
          {props.node.text}
        </Heading>
      </BlockContentRowStyle>
    );
  };

  const linkWord = (props: any) => {
    const { mark: { url }, children } = props;
    if(url == null || children == null) return null;
    const { internalLink, fileUploadUrl } = url;

    if(fileUploadUrl){
      return <Link linkText={children} externalLink={useFileAssetQuery(fileUploadUrl.asset?._ref)} />
    }

    if (internalLink) {
      return <Link linkText={children} internalLink={getPageSlugById(internalLink._ref)} />;
    }

    return <Link linkText={children} externalLink={url.externalLink} />;
  };

  const blockContentButton = (props:any) => {
    const { internalLink, linkText, externalLink } = props.node;

    if (internalLink) {
      const inLink = internalLink ? getPageSlugById(internalLink._ref) : null;
      return <BlockContentButton>
        <Link linkText={linkText} internalLink={inLink} design="primary" />
        </BlockContentButton>;
    }
    return <BlockContentButton>
      <Link linkText={linkText} externalLink={externalLink} design="primary" />
      </BlockContentButton>;
  }

  return (
    <BlockContentStyle inverse={inverse}>
      <PortableText
        blocks={_rawBlockContent}
        serializers={{
          types: { block, heading, blockContentButton },
          marks: { linkWord },
          list: (props: any) => (
            <BlockContentRowStyle>
              <ul>{props.children}</ul>
            </BlockContentRowStyle>
          ),
        }}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      />
    </BlockContentStyle>
  );
};

export default BlockContent;
