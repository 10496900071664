import styled from "styled-components";
import { colorsInverse, PRIMARY_COLOR, mediaQuery } from "@util/constants";
import { Kontainer } from "@util/standard";

export const FooterWrapper = styled.footer`
  background-color:${PRIMARY_COLOR};
  color:${colorsInverse.textBody};
  padding-top:78px;
  padding-bottom:57px;
  ${mediaQuery.mediumDown}{
    padding-top:100px;
    padding-bottom:100px;
  }
`

export const KontainerTop = styled(Kontainer)`
  display:grid;
  grid-template-columns:1fr 150px;
  grid-gap:15px;
  ${mediaQuery.mediumDown}{
    grid-gap:68px;
    grid-template-columns: 1fr;
  }
`

export const FooterLogos = styled.div`
  .gatsby-image-wrapper{
    margin-left:auto;
    ${mediaQuery.mediumUp}{
      &:first-child{
        margin-bottom:15px;
      }
    }
  }
  ${mediaQuery.mediumDown}{
    display:flex;
    justify-content:flex-start;
    .gatsby-image-wrapper{
      margin-left:initial;
      &:not(:last-child){
        margin-right:35px;
      }
    }
  }
`

export const FooterMainNav = styled.nav`
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  grid-gap:100px;
  ${mediaQuery.mediumDown}{
    grid-template-columns:1fr 1fr;
    grid-gap:35px;
  }
  h3{
    color:${colorsInverse.textBody};
    opacity:.5;
    margin-bottom:23px;
    font-size:15px;
  }
  ul{
    list-style-type:none;
    li:not(:last-child){
      margin-bottom:17px;
      a{
        font-size:14px;
      }
    }
  }
`

export const ColumnLink = styled.div`
  a{
    color:#fff;
    &:hover{
      opacity:0.5;
    }
  }
  ${mediaQuery.mediumDown}{
    &:last-child{
      grid-column: span 2;
    }
  }
`

export const KontainerBottom = styled(Kontainer)`
  margin-top:15px;
  text-align:right;
  display:block;
  ${mediaQuery.mediumDown}{
    text-align:left;
  }
  ul{
    display:inline-block;
    list-style-type:none;
    text-align:right;
    li{
      display:inline-block;
      a{
        font-size:14px;
        &:hover{
          opacity:0.5;
        }
      }
    }
    li:not(:last-child){
      margin-right:15px;
    }
  }
  
`
