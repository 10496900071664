import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";


const GetSiteConfig = () => {

  const { sanitySiteConfig }: Query = useStaticQuery(graphql`{
    sanitySiteConfig {
      googleApiKey
    }
  }`)
  

  
  return sanitySiteConfig

}

export default GetSiteConfig;