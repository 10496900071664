import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const AllPeopleQuery = () => {
  const { allSanityPeople }: Query = useStaticQuery(graphql`
    {
      allSanityPeople(sort: { order: DESC, fields: orderRank }) {
        nodes {
          _id
          name
          position
          bio
          profileImage {
            ...sanityImageFullWidth
          }
        }
      }
    }
  `);

  return allSanityPeople;
};

export default AllPeopleQuery;
