import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const FooterQuery = () => {

  const { sanityFooter }: Query = useStaticQuery(graphql`{
    sanityFooter {
      links {
        links {
          ... sanityLink
        }
        columnTitle
        _key
      }
      footerLogo {
        ... sanityImageFullWidth
      }
      footerLogoSecondary {
        ... sanityImageFullWidth
      }
      bottomLinks {
        ... sanityLink
      }
    }
  }`)

  return sanityFooter

}

export default FooterQuery